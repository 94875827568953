import React from 'react'

import SignIn from '@/components/auth/screens/SignIn'
import PageTitle from '@/components/shared/common/PageTitle'
import { useTranslations } from '@/hooks/useTranslations'

export default function SignInPage() {
  const t = useTranslations()

  return (
    <>
      <PageTitle title={t('auth.labels.signIn')} />
      <SignIn />
    </>
  )
}
